import { useTranslation } from '@infra/i18n';
import React from 'react';

import { PageContainer, Wrapper } from './styled-components';

const TermsConditionsContent = () => {
  const { t } = useTranslation('termsAndConditionsPage');

  return (
    <PageContainer>
      <Wrapper dangerouslySetInnerHTML={{ __html: t('text1') }} />
      <Wrapper dangerouslySetInnerHTML={{ __html: t('text2') }} />
    </PageContainer>
  );
};

export { TermsConditionsContent };
