import { LayoutDefault } from '@features/layouts/layout-default';
import { SEO } from '@features/seo';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { StaticSiteHero } from '@organisms/static-site-hero';
import { TermsConditionsContent } from '@organisms/terms-conditions-content';
import React from 'react';

const TermsConditionsPage = props => {
  const {
    pageContext: { locale },
    location,
  } = props;

  return (
    <LayoutDefault locale={locale} location={location}>
      <SEO
        location={location}
        title="termsAndConditionsPage.metaTitle"
        description="termsAndConditionsPage.metaDescription"
        lang={locale}
      />
      <StaticSiteHero title="termsAndConditionsPage.termsAndConditionsOfService" />
      <TermsConditionsContent />
    </LayoutDefault>
  );
};

TermsConditionsPage.propTypes = {
  ...gatsbyPageProps,
};

export { TermsConditionsPage };
export default TermsConditionsPage;
